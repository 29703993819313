document.addEventListener('DOMContentLoaded', () => {
    // Check if the slider is applicable (only on homepage with headerSlides array)
    if (!document.querySelector('#header[data-homepage="true"]') || typeof headerSlides === 'undefined' || !headerSlides.length) {
        return;
    }

    // Elements to update dynamically
    const headerImage = document.querySelector('.header-image');
    const firstLineElement = document.querySelector('.subheading-block .first-line');
    const secondLineElement = document.querySelector('.subheading-block .second-line');
    const dots = document.querySelectorAll('.slider-pagination .dot');

    // Slider Configuration
    let currentSlide = 0;
    const slideInterval = 5000; // 5 seconds

    // Check required elements
    if (!headerImage || !firstLineElement || !secondLineElement || !dots.length) {
        console.error('Missing elements for slider functionality!');
        return;
    }

    // Function to Update Slide Content
    const updateSlide = (index) => {
        const slide = headerSlides[index];
        if (slide.image) {
            headerImage.src = slide.image;
        } else {
            console.warn('Missing image for slide', index);
        }
        firstLineElement.textContent = slide.first_line || '';
        secondLineElement.textContent = slide.second_line || '';

        // Update active dot
        dots.forEach((dot, idx) => {
            const icon = dot.querySelector('i');
            if (idx === index) {
                dot.classList.add('active');
                icon.classList.replace('fa-regular', 'fa-solid'); // Switch to solid for active
            } else {
                dot.classList.remove('active');
                icon.classList.replace('fa-solid', 'fa-regular'); // Switch to regular for inactive
            }
        });
    };

    // Function to Go to Specific Slide
    const goToSlide = (index) => {
        currentSlide = index;
        updateSlide(currentSlide);
        resetInterval(); // Reset auto-advance timer
    };

    // Auto-advance Logic
    let autoAdvance = setInterval(() => {
        currentSlide = (currentSlide + 1) % headerSlides.length;
        updateSlide(currentSlide);
    }, slideInterval);

    const resetInterval = () => {
        clearInterval(autoAdvance);
        autoAdvance = setInterval(() => {
            currentSlide = (currentSlide + 1) % headerSlides.length;
            updateSlide(currentSlide);
        }, slideInterval);
    };

    // Event Listeners for Dots
    dots.forEach((dot, index) => {
        dot.addEventListener('click', () => {
            goToSlide(index);
        });
    });

    // Initialize First Slide
    updateSlide(currentSlide);
});