(function() {
document.addEventListener('DOMContentLoaded', function () {
    const jobSlider = document.querySelector('.job-slider');
    const jobPostings = document.querySelectorAll('.job-posting');
    const paginationContainer = document.querySelector('.job-slider-pagination');

    if (!jobSlider || jobPostings.length <= 2 || !paginationContainer) {
        return; // No pagination needed if there are 2 or fewer job postings
    }

    const postsPerPage = 2;
    const totalPosts = jobPostings.length;
    const totalPages = Math.ceil(totalPosts / postsPerPage);
    let currentPage = 0;

    // Helper function to update visible posts
    const updateVisiblePosts = () => {
        jobPostings.forEach((post, index) => {
            const start = currentPage * postsPerPage;
            const end = start + postsPerPage;
            if (index >= start && index < end) {
                post.classList.add('visible');
            } else {
                post.classList.remove('visible');
            }
        });

        // Update active dot
        const dots = paginationContainer.querySelectorAll('button');
        dots.forEach((dot, index) => {
            dot.classList.toggle('active', index === currentPage);
        });
    };

    // Helper function to create pagination dots
    const createPaginationDots = () => {
        for (let i = 0; i < totalPages; i++) {
            const dot = document.createElement('button');
            dot.classList.add('pagination-dot');
            dot.setAttribute('aria-label', `Go to page ${i + 1}`);
            dot.setAttribute('tabindex', '0');
            if (i === currentPage) {
                dot.classList.add('active');
            }
            dot.addEventListener('click', () => {
                currentPage = i;
                updateVisiblePosts();
            });
            paginationContainer.appendChild(dot);
        }
    };

    // Initialize
    createPaginationDots();
    updateVisiblePosts();
});
})();