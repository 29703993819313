document.addEventListener('DOMContentLoaded', function () {
    const headings = document.querySelectorAll('article .entry-content h2, article .entry-content h3');
    const indexContainer = document.getElementById('content-index');

    if (!indexContainer) return;

    let index = 0;

    // Function to determine hierarchy level (h2 = 2, h3 = 3, etc.)
    function getHeadingLevel(tagName) {
        return parseInt(tagName.replace('h', ''), 10);
    }

    // Dynamically build the index
    let lastLevel = 0;
    let currentParent = indexContainer;

    for (const heading of headings) {
        const headingId = `section-${index}`;
        heading.id = headingId;

        const headingLevel = getHeadingLevel(heading.tagName);

        // Create a new list for nested levels
        if (headingLevel > lastLevel) {
            const nestedList = document.createElement('div');
            nestedList.className = 'nested-index';
            currentParent.appendChild(nestedList);
            currentParent = nestedList;
        }
        // Go back to the parent level
        else if (headingLevel < lastLevel) {
            let levelsUp = lastLevel - headingLevel;
            while (levelsUp-- && currentParent.parentElement) {
                currentParent = currentParent.parentElement.closest('.nested-index') || indexContainer;
            }
        }

        // Add the index item
        const indexItem = document.createElement('div');
        indexItem.className = 'index-item';
        if (heading.tagName === 'H3') {
            indexItem.classList.add('subheading');
        }
        indexItem.innerHTML = `
            <a href="#${headingId}" aria-label="Navigate to section: ${heading.textContent}">
                ${heading.textContent}
            </a>`;
        currentParent.appendChild(indexItem);

        lastLevel = headingLevel;
        index++;
    }

    const indexItems = document.querySelectorAll('.index-item a');

    // Function to update the active state
    function updateActiveIndex() {
        let lastActiveIndex = -1;

        headings.forEach((heading, i) => {
            const rect = heading.getBoundingClientRect();
            const offset = 100; // Adjust this value to fine-tune when a section becomes active
            if (rect.top <= offset && rect.bottom > 0) {
                lastActiveIndex = i;
            }
        });

        indexItems.forEach((item, i) => {
            item.classList.toggle('selected', i === lastActiveIndex);
        });
    }

    // Add scroll event listener to highlight active section
    window.addEventListener('scroll', updateActiveIndex);
    updateActiveIndex(); // Initial call to set the active state
});