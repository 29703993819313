document.addEventListener('DOMContentLoaded', () => {
    const faqSection = document.querySelector('section.featureblock-faqs');
    if (!faqSection) return;

    const slides = document.querySelectorAll('.faq-slide');
    const dotsContainer = document.querySelector('.faq-actions .pagination-dots');
    const prevButton = document.querySelector('.faq-button-prev');
    const nextButton = document.querySelector('.faq-button-next');
    const autoAdvanceInterval = 5000; // Time in milliseconds for auto-advance
    let currentSlide = 0;
    let startX = 0;
    let endX = 0;
    let autoAdvanceTimer;

    // Initialize Pagination Dots
    slides.forEach((slide, index) => {
        const dot = document.createElement('span');
        dot.classList.add('dot');
        if (index === 0) dot.classList.add('active'); // Mark the first dot as active
        dot.addEventListener('click', () => goToSlide(index));
        dotsContainer.appendChild(dot);
    });

    const dots = document.querySelectorAll('.pagination-dots .dot');

    // Function to Show Only Current Slide
    const updateSlides = () => {
        slides.forEach((slide, index) => {
            slide.style.display = index === currentSlide ? 'block' : 'none';
        });

        // Update Active Dot
        dots.forEach((dot, index) => {
            dot.classList.toggle('active', index === currentSlide);
        });
    };

    // Function to Go to a Specific Slide
    const goToSlide = (index) => {
        currentSlide = (index + slides.length) % slides.length; // Loop around slides
        updateSlides();
    };

    // Function to Start Auto-Advance
    const startAutoAdvance = () => {
        stopAutoAdvance(); // Clear any existing timer
        autoAdvanceTimer = setInterval(() => {
            goToSlide(currentSlide + 1); // Move to the next slide
        }, autoAdvanceInterval);
    };

    // Function to Stop Auto-Advance
    const stopAutoAdvance = () => {
        clearInterval(autoAdvanceTimer);
    };

    // Touch Events for Swipe
    const touchStart = (e) => {
        startX = e.touches[0].clientX;
        stopAutoAdvance(); // Pause auto-advance during interaction
    };

    const touchEnd = (e) => {
        endX = e.changedTouches[0].clientX;
        const deltaX = endX - startX;

        if (deltaX > 50) {
            goToSlide(currentSlide - 1); // Swipe Right
        } else if (deltaX < -50) {
            goToSlide(currentSlide + 1); // Swipe Left
        }
        startAutoAdvance(); // Resume auto-advance
    };

    // Navigation Buttons
    if (prevButton) {
        prevButton.addEventListener('click', () => {
            goToSlide(currentSlide - 1);
            stopAutoAdvance(); // Pause auto-advance during interaction
            startAutoAdvance(); // Resume auto-advance
        });
    }

    if (nextButton) {
        nextButton.addEventListener('click', () => {
            goToSlide(currentSlide + 1);
            stopAutoAdvance(); // Pause auto-advance during interaction
            startAutoAdvance(); // Resume auto-advance
        });
    }

    // Add Touch Event Listeners to the Slider
    const slider = document.querySelector('.faq-slider');
    slider.addEventListener('touchstart', touchStart);
    slider.addEventListener('touchend', touchEnd);

    // Initialize the Slider
    updateSlides();

    // Start Auto-Advance
    startAutoAdvance();
});