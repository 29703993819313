document.addEventListener("DOMContentLoaded", function () {
    if (!document.querySelector('section.resources-container')) {
        return;
    }

    // Configuration Variables
    const POSTS_PER_PAGE = 10;

    // DOM Elements
    const serviceFilter = document.getElementById("filter-service");
    const topicFilter = document.getElementById("filter-topic");
    const searchInput = document.getElementById("search-keyword");
    const postsContainer = document.getElementById("resources-posts");
    const loadMoreButton = document.getElementById("load-more-button");
    const loadMoreContainer = document.getElementById("load-more-container");

    // Pagination State
    let currentPage = 1;
    let totalPages = 1;

    // Category Mapping
    let categoryMap = {}; // { categoryId: categoryObject }
    let debounce; // Add the debounce variable

    // Build the category map
    if (window.allCategories) {
        window.allCategories.forEach((category) => {
            categoryMap[category.term_id] = {
                id: category.term_id,
                name: category.name,
                parent: category.parent,
            };
        });
    }

    // Helper Function: Resolve Categories for a Post
    const resolvePostCategories = (categories) => {
        let service = null; // Parent category
        const topics = [];

        categories.forEach((cat) => {
            const category = categoryMap[cat.id];
            if (category.parent === 0) {
                service = category.name;
            } else {
                topics.push(category.name);
                if (!service && categoryMap[category.parent]) {
                    service = categoryMap[category.parent].name;
                }
            }
        });

        return { service: service || "Uncategorized", topics };
    };

    // Fetch and Display Posts
    const fetchAndDisplayPosts = async (page = 1, append = false) => {
        const selectedService = serviceFilter.value;
        const selectedTopic = topicFilter.value;
        const searchKeyword = searchInput.value.trim();

        const params = new URLSearchParams({
            per_page: POSTS_PER_PAGE,
            _embed: true,
            page,
        });

        if (selectedTopic !== "all") {
            params.append("categories", selectedTopic);
        } else if (selectedService !== "all") {
            params.append("categories", selectedService);
        }

        if (searchKeyword.length >= 3) {
            params.append("search", searchKeyword);
        }

        const apiUrl = `/wp-json/wp/v2/posts?${params.toString()}`;

        try {
            const response = await fetch(apiUrl);
            if (!response.ok) throw new Error(`Error: ${response.statusText}`);

            const posts = await response.json();
            totalPages = parseInt(response.headers.get("X-WP-TotalPages"), 10) || 1;

            if (!append) postsContainer.innerHTML = ""; // Clear container if not appending

            posts.forEach((post) => {
                const categories = post._embedded?.["wp:term"]?.[0] || [];
                const { service, topics } = resolvePostCategories(categories);

                const topicsHtml = topics.length > 0 ? topics.join(" &middot; ") : "";

                const postHtml = `
                    <article class="resource-item">
                        <div class="resource-thumbnail">
                            ${
                                post._embedded?.["wp:featuredmedia"]?.[0]?.source_url
                                    ? `<img src="${post._embedded["wp:featuredmedia"][0].source_url}" alt="${post.title.rendered}" />`
                                    : `<div class="placeholder-thumbnail"></div>`
                            }
                        </div>
                        <div class="resource-details">
                            <div class="resource-meta">
                                <span class="service">${service}</span>
                                ${
                                    topicsHtml
                                        ? `<span class="topic"><span class="dot">&#8226;</span> ${topicsHtml}</span>`
                                        : ""
                                }
                            </div>
                            <h3 class="resource-title"><a href="${post.link}">${post.title.rendered}</a></h3>
                            <div class="resource-excerpt">${post.excerpt.rendered}</div>
                            <a class="resource-read-more" href="${post.link}" aria-label="Read more about ${post.title.rendered}">Read More</a>
                        </div>
                    </article>
                `;

                if (append) {
                    postsContainer.insertAdjacentHTML("beforeend", postHtml);
                } else {
                    postsContainer.insertAdjacentHTML("beforeend", postHtml);
                }
            });

            currentPage = page;
            if (currentPage >= totalPages) {
                loadMoreContainer.style.display = "none";
            } else {
                loadMoreContainer.style.display = "block";
                loadMoreButton.disabled = false;
                loadMoreButton.textContent = "Load More...";
            }
        } catch (error) {
            console.error("Error fetching posts:", error);
            postsContainer.innerHTML = "<p>Failed to load posts. Please try again later.</p>";
        }
    };

    // Fetch and Populate Subcategories
    const fetchAndPopulateSubcategories = (serviceId) => {
        topicFilter.innerHTML = '<option value="all">All Topics</option>';
        const subcategories = Object.values(categoryMap).filter((cat) => cat.parent === parseInt(serviceId));
        subcategories.forEach((subcategory) => {
            const option = document.createElement("option");
            option.value = subcategory.id;
            option.textContent = subcategory.name;
            topicFilter.appendChild(option);
        });
        topicFilter.disabled = subcategories.length === 0;
    };

    // Event Listeners
    serviceFilter.addEventListener("change", () => {
        const selectedService = serviceFilter.value;
        if (selectedService === "all") {
            topicFilter.innerHTML = '<option value="all">All Topics</option>';
            topicFilter.disabled = true;
        } else {
            fetchAndPopulateSubcategories(selectedService);
        }
        currentPage = 1;
        fetchAndDisplayPosts(currentPage, false);
    });

    topicFilter.addEventListener("change", () => {
        currentPage = 1;
        fetchAndDisplayPosts(currentPage, false);
    });

    searchInput.addEventListener("input", () => {
        clearTimeout(debounce);
        debounce = setTimeout(() => {
            currentPage = 1;
            fetchAndDisplayPosts(currentPage, false);
        }, 300);
    });

    loadMoreButton.addEventListener("click", () => {
        if (currentPage < totalPages) fetchAndDisplayPosts(currentPage + 1, true);
    });

    // Initialize the Page
    fetchAndDisplayPosts();
});