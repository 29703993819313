document.addEventListener('DOMContentLoaded', function() {
    const profileContainer = document.querySelector('.directory-container');

    if (!profileContainer) {
        return;
    }

    const filterLinks = profileContainer.querySelectorAll('.filter-link');
    const profileCards = profileContainer.querySelectorAll('.profile-card');

    // Helper function to filter profiles
    const filterProfiles = (filter) => {
        profileCards.forEach((card) => {
            const rank = card.getAttribute('data-rank');
            if (filter === 'all' || rank === filter) {
                card.style.display = 'grid';
            } else {
                card.style.display = 'none';
            }
        });
    };

    // Event listeners for filter links
    filterLinks.forEach((link) => {
        link.addEventListener('click', (e) => {
            e.preventDefault();

            // Remove active state from all links
            filterLinks.forEach((link) => link.classList.remove('active'));

            // Add active state to the clicked link
            link.classList.add('active');

            // Filter profiles based on the clicked link's data-filter value
            const filter = link.getAttribute('data-filter');
            filterProfiles(filter);
        });
    });

    // Set the initial state (Show All)
    filterProfiles('all');
});