// entry file for @wordpress/scripts build processes
import "./js/nav.js";
import "./js/header-slider.js";
import "./js/indexable-page.js";
import "./js/profiles.js";
import "./js/values.js";
import "./js/employment.js";
import "./js/faq-slider.js";
import "./js/resources.js";
import "./js/testimonials.js";

// addEventListener("DOMContentLoaded", (event) => { console.log('Bean Robinson is loaded.'); });