document.addEventListener('DOMContentLoaded', function () {
    const valuesContainer = document.querySelector('section.values-container');
    const detailsElements = document.querySelectorAll('.values-section details');
    const imageElement = document.querySelector('.title-section img');

    if (!valuesContainer || !detailsElements.length || !imageElement) {
        return;
    }

    const updateImage = () => {
    const openDetails = document.querySelector('.values-section details[open]');
    if (openDetails) {
        const imageUrl = openDetails.getAttribute('data-image');
        if (imageUrl) {
            imageElement.src = imageUrl;
            imageElement.style.display = 'block'; // Ensure the image is visible

            // Wait for the image to load before recalculating layout
            imageElement.onload = function () {
                const titleSection = document.querySelector('.title-section');
                if (titleSection) {
                    titleSection.style.height = `${imageElement.offsetHeight}px`;
                }
            };
        } else {
            imageElement.style.display = 'none'; // Hide image if no URL
        }
    } else {
        imageElement.style.display = 'none'; // Hide image if nothing is open
    }
};

    // On page load, set the image for the open value
    updateImage();

    // Add toggle listeners to each details element
    detailsElements.forEach(details => {
        details.addEventListener('toggle', function () {
            if (details.open) {
                // Close all other open details
                detailsElements.forEach(otherDetails => {
                    if (otherDetails !== details && otherDetails.open) {
                        otherDetails.open = false;
                    }
                });

                // Update the image
                updateImage();
            }
        });
    });
});